import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const PrivacyPolicy = () => {

    return (
        <>
            <SEO title="Privacy Policy" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Privacy Policy"
                page="Privacy Policy"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <div className="section-title">
                                    <h5 className="title">This Privacy policy was published on March 8th, 2023.</h5>
                                </div>
                                <p>Codestormx is committed to respecting and protecting your privacy on our website. This privacy policy is designed to provide you an outline of, what personal information is collected from you and why.</p>
                                <h4>What information do we collect?</h4>
                                <p>We collect information such as your name, organization name, email address, telephone numbers, mobile number, fax number, physical address, postal address, IP address and other contact or project related information for internal use only.
                                </p>
                                <h4>How information is collected?</h4>
                                <p>The information may be collected via our website by any technology used on our website for example the contact us form or a Quote request form.
We may also collect personal information via telephone, letter, promotional materials or at any function or event when meeting with a synapse representative.</p>
                                <h4>Why collect information?</h4>
                                <p>Our primary goal in collecting your personal information is to provide you with a professional, efficient and personalized service.</p>
                                <h4>Using personal information:</h4>
                                <p>We may use your personal information to communicate with you via emails, telephone, newsletters, and direct mails. We may also use it to provide important updates, for invitations or for any other administrative purpose.</p>
                                <h4>Sharing your information:</h4>
                                <p>We may share your personal information on a need-to-know basis within our company. We may also share your information with our sub-contractors or our suppliers with whom we have signed a confidentiality agreement. We will not sell, lease or distribute your personal information to any third-party organization without prior consent. We reserve the right to reveal your information in extreme or out-of-ordinary circumstances or for health or safety reasons or as required by the legal authorities.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default PrivacyPolicy;