import React from 'react'
import PropTypes from "prop-types";


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>Codestormx | {title}</title>
            {/* <meta name="robots" content="noindex, follow" /> */}
            <meta name="description" content="Web development, Mobile app development, Startup business development, Digital marketing" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta property="og:type" content="website"/>
            <meta property="og:url" content="https://www.codestormx.in"/>
            <meta property="og:title" content="Codestormx | Creating the best digital solutions for your business"/>
            <meta property="og:description" content="Web development, Mobile app development, Startup business development, Digital marketing"/>
            {/* <meta property="og:image" content="https://www.codestormx.in/assets/images/codestormx_cover.png"/> */}
            <meta property="twitter:card" content="summary_large_image"/>
            <meta property="twitter:url" content="https://www.codestormx.in"/>
            <meta property="twitter:title" content="Codestormx | Creating the best digital solutions for your business"/>
            <meta property="twitter:description" content="Web development, Mobile app development, Startup business development, Digital marketing"/>
            {/* <meta property="twitter:image" content="https://www.codestormx.in/assets/images/codestormx_cover.png"/> */}
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;