import React, {useState, useEffect} from 'react';
import BannerOne from '../component/banner/BannerOne';
import HeaderOne from '../common/header/HeaderOne';
import FooterOne from '../common/footer/FooterOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ServicePropOne from '../component/service/ServicePropOne';
import CounterUpOne from '../component/counterup/CounterUpOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProjectFive from '../component/project/ProjectFive';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SplashData from '../data/splash/SplashData.json';
import TeamOne from '../component/team/TeamOne';
import { slugify } from '../utils';
import { FaAngleRight } from "react-icons/fa";
import { Helmet } from 'react-helmet';

import ReactGa from 'react-ga';

const DemoData = SplashData[0];
const FeatureData = SplashData[1];

const HomePage = () => {
    useEffect(() => {
        ReactGa.pageview(window.location.pathname);
    }, [])
  return (
    <>
    <Helmet>
        <title>Codestormx | Web and Mobile app development, Digital marketing</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#9A91FF" />
        <meta name="description" content="Web development, Mobile app development, Startup business development, Digital marketing" />
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.codestormx.in"/>
        <meta property="og:title" content="Codestormx | Creating the best digital solutions for your business"/>
        <meta property="og:description" content="Web development, Mobile app development, Startup business development, Digital marketing"/>
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.codestormx.in"/>
        <meta property="twitter:title" content="Codestormx | Creating the best digital solutions for your business"/>
        <meta property="twitter:description" content="Web development, Mobile app development, Startup business development, Digital marketing"/>
    </Helmet>
    {/* <SEO title="Web and Mobile app development, Digital marketing" /> */}
    <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderOne />
            <BannerOne />
            <div id="cdx-services" className="section section-padding bg-color-light">
                <div className="container">
                    <SectionTitle 
                        subtitle="BEST SERVICES"
                        title="We help to create strategies, design & development."
                        description=""
                        textAlignment="heading-left"
                        textColor=""
                    />
                    <div className="row">
                        <ServicePropOne colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" itemShow="6" marginTop="no" />
                    </div>
                </div>
            </div>
            <CounterUpOne />
            <ProjectFive colSize ="row-cols-sm-2 row-cols-lg-3 row-cols-xl-4" parentClass="project-column-4" perPageShow="8"/>
            {/* <TeamOne /> */}
            <CtaLayoutOne /> 
            <FooterOne parentClass="" />
        </main>
    </>
  )
}

export default HomePage