import React from 'react';
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas'
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaBehance, FaPhone, FaFax, FaWhatsapp, FaInstagram, FaYoutube, FaGithub, FaDribbble } from "react-icons/fa";


const OffcanvasMenu = ({offcanvasShow, offcanvasHide}) => {
    return (
        <Offcanvas show={offcanvasShow} onHide={offcanvasHide} placement="end" className="header-offcanvasmenu">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                {/* <form action="#" className="side-nav-search-form">
                    <div className="form-group">
                        <input type="text" className="search-field" name="search-field" placeholder="Search..." />
                        <button className="side-nav-search-btn"><i className="fas fa-search"></i></button>
                    </div>
                </form> */}
                <div className="row ">
                    {/* <div className="col-lg-5 col-xl-6">
                        <ul className="main-navigation list-unstyled">
                            <li><Link to={process.env.PUBLIC_URL + "/digital-agency"}>Home</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/#cdx-services"}>Services</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/#cdx-projects"}>Projects</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
                        </ul>
                    </div> */}
                    <div className="col-lg-12 col-xl-12">
                        <div className="contact-info-wrap">
                            <div className="contact-inner">
                                <address className="address">
                                    <span className="title">Contact Information</span>
                                    <p>Tirupur <br /> Tamil Nadu, INDIA</p>
                                </address>
                                <address className="address">
                                    <span className="title">We're Available 24/7.</span>
                                    <a href="https://wa.me/918870427857" className="tel"><FaWhatsapp /> +91 887 04 27857</a>
                                    <a href="https://wa.me/917904141862" className="tel"><FaWhatsapp /> +91 790 41 41862</a>
                                </address>
                            </div>
                            <div className="contact-inner">
                                <h5 className="title">Find us here</h5>
                                <div className="contact-social-share">
                                    <ul className="social-share list-unstyled">
                                        <li><a href="https://www.instagram.com/codestormx.in/"><FaInstagram /></a></li>
                                        <li><a href="https://www.youtube.com/channel/UCMrc7COBma48LbxH8HZ10CA"><FaYoutube /></a></li>
                                        <li><a href="https://github.com/CodeStormX/"><FaGithub /></a></li>
                                        <li><a href="https://dribbble.com/suryavmds"><FaDribbble /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default OffcanvasMenu;
