import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import ReactGa from 'react-ga';
import 'bootstrap/dist/css/bootstrap.min.css';

// Pages 
import HomePage from './pages/HomePage';
import Contact from './pages/Contact';
import ErrorPage from './pages/404';
import ComingSoon from './pages/ComingSoon';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';

// Css Import
import './assets/scss/app.scss';

const TRACKING_ID = "G-MEL4GFKG9G";
ReactGa.initialize(TRACKING_ID);


const App = () => {
  return (
    <Router>
		<ScrollToTop>
			<Routes>
				<Route path={process.env.PUBLIC_URL + "/"} element={<HomePage />}/>
				<Route path={process.env.PUBLIC_URL + "/contact"} element={<Contact />}/>
				{/* <Route path={process.env.PUBLIC_URL + "/coming-soon"} element={<ComingSoon />}/> */}
				<Route path={process.env.PUBLIC_URL + "/privacy-policy"} element={<PrivacyPolicy />}/>
				<Route path={process.env.PUBLIC_URL + "/terms-use"} element={<TermsOfUse />}/>

				<Route path="*" element={<ErrorPage />}/>
			</Routes>
		</ScrollToTop>
    </Router>
  )
}

export default App;
