import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaYoutube, FaGithub, FaPinterestP, FaLinkedin, FaInstagram, FaVimeoV, FaDribbble, FaBehance, FaEnvelopeOpen } from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from '../../utils';

const getServiceData = ServiceData;

const FooterOne = ({parentClass}) => {
    
    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-top">
                    <div className="footer-social-link">
                        <ul className="list-unstyled">
                            <li><a href="https://www.instagram.com/codestormx.in/"><FaInstagram /></a></li>
                            <li><a href="https://www.youtube.com/channel/UCMrc7COBma48LbxH8HZ10CA"><FaYoutube /></a></li>
                            <li><a href="https://github.com/CodeStormX/"><FaGithub /></a></li>
                            <li><a href="https://dribbble.com/suryavmds"><FaDribbble /></a></li>
                            <li><a href="https://www.facebook.com/Codestormx-103182471475217"><FaFacebookF /></a></li>
                            <li><a href="https://in.pinterest.com/cdxinfluencer/"><FaPinterestP /></a></li>
                            <li><a href="https://www.linkedin.com/in/codestormx-business-6126ab1b1/"><FaLinkedin /></a></li>
                            {/* <li><Link to="https://www.behance.net/"><FaBehance /></Link></li> */}
                        </ul>
                    </div>
                </div>
                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-6 col-lg-5">
                            <div className="footer-widget border-end">
                                <div className="footer-newsletter">
                                    <h2 className="title">Who we are!</h2>
                                    <p>Team of young passionate minds, our goal is to provide high-end technology upgrade to the world</p>
                                    {/* <form>
                                        <div className="input-group">
                                            <span className="mail-icon"><FaEnvelopeOpen /> </span>
                                            <input type="email" className="form-control" placeholder="Email address" />
                                            <button className="subscribe-btn" type="submit">Subscribe</button>
                                        </div>
                                    </form> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Services</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Interface design</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Mobile development</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Web development</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Digital marketing</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/contact"}>E-commerce</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/contact"}>PWA development</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Resources</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><a href='https://www.sendiee.com'>WhatsApp API</a></li>
                                                <li><a href='#'>QR code generator</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Support</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
                                                <li> <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Terms and conditions</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom"> 
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by <a href="https://codestormx.in/">Codestormx</a>.</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="footer-bottom-link">
                                <ul className="list-unstyled">
                                    <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Terms and conditions</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;